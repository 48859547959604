































































































































import Vue from 'vue';
import AppMainSchedule from '@/components/AppMainSchedule.vue';
import ScheduleDay from '@/components/ScheduleDay.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListItem from '@/components/AppListItem.vue';
import AppListScheduledRoute from '@/components/AppListScheduledRoute.vue';
import AppListDelivery from '@/components/AppListDelivery.vue';
import AppListItemPhotos from '@/components/AppListItemPhotos.vue';
import ROUTE_DELETE from '@/graphql/mutations/ScheduledRouteDelete.graphql';
import DELIVERY_DELETE from '@/graphql/mutations/DeliveryDelete.graphql';
import SCHEDULE_PUBLISH from '@/graphql/mutations/SchedulePublish.graphql';
import { DELIVERY_STATUSES, ROUTE_STATUSES } from '@/utils/constants';
import { Schedule, ScheduledRoute, Delivery } from '@/types/schema';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminSchedules',
  components: {
    AppMainSchedule,
    ScheduleDay,
    AppPanel,
    AppPanelItem,
    AppListItem,
    AppListScheduledRoute,
    AppListDelivery,
    AppListItemPhotos,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
    };
  },
  methods: {
    async deleteRoute(id: ScheduledRoute['id'], cb: () => void | undefined) {
      if (!id) return;
      if (!confirm("Are you sure? This action can't be undone.")) return;

      await this.$apollo.mutate({
        mutation: ROUTE_DELETE,
        variables: {
          input: { id },
        },
      });
      if (cb) cb();
      this.$notify({
        text: 'Route deleted!',
        type: 'success',
        duration: 6000,
      });
    },
    async deleteDelivery(id: Delivery['id'], cb: () => void | undefined) {
      if (!id) return;
      if (!confirm("Are you sure? This action can't be undone.")) return;

      await this.$apollo.mutate({
        mutation: DELIVERY_DELETE,
        variables: {
          input: { id },
        },
      });
      if (cb) cb();
      this.$notify({
        text: 'Delivery deleted!',
        type: 'success',
        duration: 6000,
      });
    },
    async publishSchedule(id: Schedule['id']) {
      if (!id) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: SCHEDULE_PUBLISH,
          variables: {
            input: {
              scheduleId: id,
            },
          },
        });
        this.$notify({
          text:
            'All drivers have been notified that this schedule has been published.',
          type: 'success',
          duration: 8000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    isRouteUnstarted(status: string) {
      return status === ROUTE_STATUSES.UNSTARTED;
    },
    isRouteStarted(status: string) {
      return status === ROUTE_STATUSES.STARTED;
    },
    isDeliveryComplete(status: string) {
      return status === DELIVERY_STATUSES.COMPLETED;
    },
  },
});
