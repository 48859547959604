var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppMainSchedule',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var schedule = ref.schedule;
var refetch = ref.refetch;
return [_c('AppPanel',[_vm._l((schedule.days),function(day){return _c('ScheduleDay',{key:day.date,attrs:{"date":day.date}},[_vm._l((day.routes),function(route){return _c('AppPanelItem',{key:("route-" + (route.id)),attrs:{"title":route.name,"status":route.status,"subtitle":("Pick Up → " + (route.driver.fullName)),"icon":"farm"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.deleteRoute(route.id, refetch)}}},[_vm._v(" Remove Route ")]),(_vm.isRouteUnstarted(route.status))?_c('v-btn',{attrs:{"to":{
                name: 'admin-route-start',
                params: { id: route.id },
                query: { backDate: day.date },
              },"outlined":""}},[_vm._v(" Start Route ")]):_vm._e(),(_vm.isRouteStarted(route.status))?_c('v-btn',{attrs:{"to":{
                name: 'admin-route-complete',
                params: { id: route.id },
                query: { backDate: day.date },
              },"outlined":""}},[_vm._v(" Complete Route ")]):_vm._e()]},proxy:true}],null,true)},[_c('AppListItem',{attrs:{"icon":"mdi-account-circle"}},[_vm._v(" "+_vm._s(route.driver.fullName)+" ")]),_c('AppListScheduledRoute',{attrs:{"route":route}}),(route.delivery)?_c('AppListItem',{attrs:{"icon":"mdi-link"}},[_vm._v(" "+_vm._s(route.delivery.name)+" ")]):_vm._e(),(route.farmTickets && route.farmTickets.length > 0)?_c('AppListItemPhotos',{attrs:{"photos":route.farmTickets,"label":"Farm Tickets"}}):_vm._e()],1)}),_vm._l((day.deliveries),function(delivery){return _c('AppPanelItem',{key:("delivery-" + (delivery.id)),attrs:{"title":delivery.location.name,"status":delivery.status,"subtitle":("Delivery → " + (delivery.driver.fullName)),"icon":"dairy"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.deleteDelivery(delivery.id, refetch)}}},[_vm._v(" Remove Delivery ")]),(!_vm.isDeliveryComplete(delivery.status))?_c('v-btn',{attrs:{"to":{
                name: 'admin-delivery-complete',
                params: { id: delivery.id },
                query: { backDate: day.date },
              },"outlined":""}},[_vm._v(" Complete Delivery ")]):_vm._e()]},proxy:true}],null,true)},[_c('AppListItem',{attrs:{"icon":"mdi-account-circle"}},[_vm._v(" "+_vm._s(delivery.driver.fullName)+" ")]),_c('AppListDelivery',{attrs:{"delivery":delivery}}),(delivery.route)?_c('AppListItem',{attrs:{"icon":"mdi-link"}},[_vm._v(" "+_vm._s(delivery.route.name)+" ")]):_vm._e()],1)}),_c('v-btn',{staticClass:"w-full",attrs:{"outlined":"","to":{
            name: 'admin-schedules-add',
            params: { date: day.date },
          }}},[_vm._v(" Add Item ")])],2)}),_c('v-btn',{staticClass:"w-full mt-6",attrs:{"disabled":schedule.published || _vm.loading,"loading":_vm.loading,"color":"accent"},on:{"click":function($event){return _vm.publishSchedule(schedule.id)}}},[_vm._v(" Publish Schedule ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }